<template>
    <base-card>
      <form @submit.prevent="customerRequest">
        <div class="form-group">
          <label for="first-name">First Name</label>
          <input
            type="text"
            class="form-control"
            id="first-name"
            v-model="firstName"
          />
        </div>
        <div class="form-group">
          <label for="last-name">Last Name</label>
          <input
            type="text"
            class="form-control"
            id="last-name"
            v-model="lastName"
          />
        </div>
        <div class="form-group">
          <label for="phone">Enter your phone number:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            class="form-control"
            placeholder="07...."
            v-model="phoneNumber"
          />
        </div>
        <p v-if="!formIsValid" style="color: red">Please enter all the required information!</p>
        <button class="btn btn-primary">Submit</button>
      </form>
    </base-card>
  </template>
  
  <script>
  export default {
    data() {
      return {
        firstName: "",
        lastName: "",
        phoneNumber: null,
        selectedProduct: null,
        formIsValid: true
      };
    },
    methods: {
      customerRequest() {
      
      this.formIsValid = true
  
      if(this.firstName === '' || this.lastName === '' || this.phoneNumber === null) {
        this.formIsValid = false
        return
      }
  
      const productId = this.$route.params;
      
      // Finds the specific rental property
      this.selectedProduct = this.$store.getters["marketplace/getProducts"].find(
        (product) => product.id === productId.id
      );
        const request = {
          firstName: this.firstName,
          lastName: this.lastName,
          productId: this.$route.params.id,
          phoneNumber: this.phoneNumber,
          ownerName: this.selectedProduct.ownerName,
          ownerPhoneNumber: this.selectedProduct.ownerPhoneNumber,
          ownerEmail: this.selectedProduct.emailAddress,
          productName: this.selectedProduct.productName,
          price: this.selectedProduct.price,
          type: 'marketplace product',
          date: new Date().toUTCString()
        };
        this.$store.dispatch("marketplace/addProductRequest", request);
        this.firstName = "";
        this.lastName = "";
        this.phoneNumber = null;
        this.selectedProduct = null
  
        // console.log("this is params",this.$route.params);
  
        this.$router.replace("/rentals");
      },
    },
  };
  </script>